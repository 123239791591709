import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Error from './components/Error';
import Auth from './Auth';
import './index.css';
import './App.css';
import PopulateStorageVars from './PopulateStorageVars';

// grab first part of uri path, which will always be the uuid. [0] is always null.
let uuid = window.location.pathname.split('/')[1];
let token = localStorage.getItem('token');
let tokenTimestamp = parseInt(localStorage.getItem('tokenTimestamp'), 10);

PopulateStorageVars(uuid);
let authed = false;
if(token !== null && token !== 'undefined') {
  let tokenTimeoutWindow = parseInt(localStorage.getItem('timeoutWindow'), 10);
  let now = new Date();
  let tokenAge = (now.getTime() - tokenTimestamp) / (60*1000);

  if(tokenTimeoutWindow < tokenAge) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('tokenTimeout');

    localStorage.clear();
    window.location.reload();
  }
  if(uuid !== '') {
    try {
      authed = true;
      Auth({'uuid':uuid, 'action': 'auth'});
    } catch(error) {
      console.log("Error: " + error);
    }
  }
} else {
  localStorage.removeItem('token');
}

if(uuid !== '') {
  ReactDOM.render(
    <React.StrictMode>
      <div className="app">
        <div className="app-content">
          <main className="container">
            <App
              uuid={uuid}
              isAuthed={authed}
            />
          </main>
        </div>
      </div>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <div className="app">
        <div className="app-content">
          <main className="container">
            <Error />
          </main>
        </div>
      </div>

    </React.StrictMode>,
    document.getElementById('root')
  );
}
