import React from "react";
import Login from "./Login";
import Loading from "./Loading";
import VideoPlayer from "./VideoPlayer";
import PropTypes from "prop-types";
import TwilioVerify from "./TwilioVerify";
import ResetPic from "./ResetPic";
import Emitter from "../Emitter";
import LoginVerify from "./LoginVerify";
import Auth from "../Auth";
import jwt_decode from "jwt-decode";

interface AProps {
    uuid: string;
    isAuthed: boolean;
}

interface AState {
    hasAuthed: boolean;
    hasVerified: boolean;
    isLoaded: boolean;
    phone?: string;
    pic?: string;
    mediaSrc?: string;
    mediaType?: string;
    loginErrorMsg?: string;
    type?: string;
    authData?: any;
    resetPic?: boolean;
    authError?: boolean;
}

class App extends React.Component<AProps, AState> {
    static propTypes = {
        uuid: PropTypes.string.isRequired,
    };

    public uuid;
    public _setState;
    constructor(props) {
        super(props);
        this.state = {
            hasAuthed: this.props.isAuthed,
            hasVerified: this.props.isAuthed,
            isLoaded: false,
            phone: "",
            pic: "",
            mediaSrc: "",
            mediaType: "",
            loginErrorMsg: "",
            type: "",
            resetPic: false,
            authData: {},
            authError: false,
        };

        this.handleAuth = this.handleAuth.bind(this);
        this.handleVerify = this.handleVerify.bind(this);
        this.handleVerifyStart = this.handleVerifyStart.bind(this);
        this.handleLoaded = this.handleLoaded.bind(this);
        this.handleMediaDataFetched = this.handleMediaDataFetched.bind(this);
        this.handleLoginError = this.handleLoginError.bind(this);
        this.handleResendVerify = this.handleResendVerify.bind(this);
        this.handleResetPic = this.handleResetPic.bind(this);
        this.handleAuthError = this.handleAuthError.bind(this);
        this.uuid = props.uuid;
    }

    componentDidMount() {
        Emitter.on("VERIFY_FINISHED", this.handleVerify);
        Emitter.on("LOGIN_FINISHED", this.handleAuth);
        Emitter.on("LOADING_FINISHED", this.handleLoaded);
        Emitter.on("MEDIA_DATA_FETCHED", this.handleMediaDataFetched);
        Emitter.on("LOGIN_ERROR", this.handleLoginError);
        Emitter.on("VERIFY_SUBMIT", this.handleVerifyStart);
        Emitter.on("VERIFY_RESEND", this.handleResendVerify);
        Emitter.on("RESET_PIC", this.handleResetPic);
        Emitter.on("AUTH_ERROR", this.handleAuthError);
        if (!this.state.hasAuthed) {
            Emitter.emit("LOADING_FINISHED");
        }
    }

    handleVerify(hasVerified) {
        this.setState({
            hasVerified: hasVerified,
        });
    }

    handleAuthError(hasVerified) {
        this.setState({
            authError: true,
            isLoaded: true,
        });
    }

    handleVerifyStart(type) {
        let token = window.localStorage.getItem("token");

        let authData = {
            uuid: this.uuid,
            token: token,
            verifyType: type.type,
            action: "startVerify",
        };

        this.setState({
            authData: authData,
            type: type.type,
        });

        Auth(authData);
    }

    handleResendVerify(type) {
        let authData = this.state.authData;
        authData.verifyType = type.type;

        this.setState({
            authData: authData,
            type: type.type,
        });

        Auth(authData);
    }

    handleAuth(payload) {
        if (!!payload) {
            if (payload === true) {
                this.setState({
                    hasAuthed: true,
                    hasVerified: false,
                    authError: false,
                });
            } else {
                this.setState({
                    hasAuthed: true,
                    phone: payload.phone,
                    pic: payload.pic,
                    authError: false,
                });
            }
        } else {
            this.setState({
                hasAuthed: false,
                authError: false,
            });
        }
    }

    handleLoaded() {
        this.setState({
            isLoaded: true,
        });
    }

    handleMediaDataFetched(mediaData) {
        this.setState({
            mediaSrc: mediaData.url,
            mediaType: mediaData.contentType,
        });
    }

    handleLoginError(loginError) {
        this.setState({
            loginErrorMsg: loginError,
        });
    }

    handleResetPic(socketData) {
        this.setState({
            resetPic: socketData && !!socketData.resetPic,
        });
    }

    getAppState() {
        let token = window.localStorage.getItem("token");

        if (!this.state.isLoaded) {
            return "loading";
        }

        if (!token && !this.state.resetPic) {
            return "login";
        } else if (this.state.resetPic) {
            return "resetPic";
        }

        let appData;

        try {
            appData = jwt_decode(token);
        } catch (error) {
            console.log("Invalid token");
            return "loading";
        }

        if (this.state.mediaSrc) {
            return "playVideo";
        }

        if (
            appData &&
            appData["authentication"]["phone"] &&
            !appData["authentication"]["verifySid"]
        ) {
            return "startVerify";
        }
        if (
            appData &&
            appData["authentication"]["verifySid"] &&
            !this.state.mediaSrc
        ) {
            return "doVerify";
        }

        if (appData && this.state.authError) {
            return "login";
        }

        return "loading";
    }

    render() {
        let errorMsg = this.state.loginErrorMsg;
        let type = this.state.type;

        let appState = this.getAppState();

        let appMarkup = <Loading />;

        switch (appState) {
            case "login":
                appMarkup = <Login uuid={this.uuid} errorMsg={errorMsg} />;
                break;
            case "resetPic":
                appMarkup = <ResetPic />;
                break;
            case "startVerify":
                appMarkup = <LoginVerify />;
                break;
            case "doVerify":
                appMarkup = <TwilioVerify uuid={this.uuid} type={type} />;
                break;
            case "playVideo":
                appMarkup = (
                    <VideoPlayer
                        uuid={this.uuid}
                        mediaSrc={this.state.mediaSrc}
                        mediaType={this.state.mediaType}
                    />
                );
                break;
            default:
                appMarkup = <Loading />;
                break;
        }

        return appMarkup;
    }
}

export default App;
