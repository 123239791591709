import Axios from "axios";
import qs from "qs";
import Emitter from "./Emitter";

function Auth(authData) {
    let token = localStorage.getItem("token");

    let phoneNumber = authData.phoneNumber;
    let pic = authData.pic;
    let uuid = authData.uuid;
    let action = authData.action;

    let data = {
        object: uuid,
        action: action,
        type: authData.verifyType,
    };

    let options = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    };

    if (phoneNumber !== undefined && pic !== undefined) {
        data.phone = phoneNumber;
        data.pic = pic;
    }

    if (token !== null || token !== "undefined") {
        data.token = token;
    }

    Axios.post(window.omniFuncUrl, qs.stringify(data), options).then(
        function (response) {
            let metadata = response.data;

            if (metadata.token) {
                window.localStorage.setItem("token", metadata.token);
                window.localStorage.setItem("tokenTimestamp", Date.now());
            }
            if (!!!data.phone && response.data.url) {
                Emitter.emit("MEDIA_DATA_FETCHED", metadata);
                Emitter.emit("LOGIN_FINISHED", true);
                Emitter.emit("VERIFY_FINISHED", true);
            } else {
                Emitter.emit("VERIFY_FINISHED", false);

                window.localStorage.setItem("verifySid", metadata.verifySid);
                Emitter.emit("LOGIN_FINISHED", {
                    phone: data.phone,
                    pic: data.pic,
                });
            }

            Emitter.emit("LOADING_FINISHED");
        },
        function (error) {
            if (error.response?.status === 429) {
                Emitter.emit("VERIFY_FINISHED", false);
                Emitter.emit("LOGIN_FINISHED", {
                    phone: data.phone,
                    pic: data.pic,
                });
                return;
            }
            if (error.response.status === 403) {
                Emitter.emit("AUTH_ERROR");
                return;
            }
            Emitter.emit(
                "LOGIN_ERROR",
                "Your telephone number and/or PIC did not match our records. Please try again."
            );
            Emitter.emit("LOGIN_FINISHED", false);
        }
    );
}

export default Auth;
