'use strict mode';
import React from 'react';

class Loading extends React.Component {
  render() {
    return (
      <div><span></span></div>
    );
  }
}

export default Loading;
