'use strict mode';

import Emitter from '../Emitter';
import React from 'react';
import '../App.css';

interface RPProps {
}

interface RPState {

}

class ResetPic extends React.Component<RPProps, RPState> {

  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handlePhoneCall = this.handlePhoneCall.bind(this);
    this.handleRedirectLink = this.handleRedirectLink.bind(this);
  }

  handleCancel(event) {
    event.preventDefault();
    Emitter.emit('RESET_PIC', {resetPic: false})
  }

  handlePhoneCall(event) {
    event.preventDefault();
    window.open("tel:+18002382727");
    Emitter.emit('RESET_PIC', {resetPic: false})
  }

  handleRedirectLink(event) {
    event.preventDefault();
    window.open("https://www.myadt.com", "_blank");
    Emitter.emit('RESET_PIC', {resetPic: false})
  }

  handleNothing(event) {
    event.preventDefault();
  }

  render() {

    let logoUrl = window.localStorage.getItem('cdnUrl') + '/adt-new-logo.svg';

    return (
      <div className="form-container">
        <div className="form-header">
          <img src={logoUrl} alt="ADT Logo"/>
        </div>
        <div className="form-body">
          <h3 className="reset-pic-header">Forgot Personal Identification Code?</h3>
          <h4 className="reset-pic-subheader">Reset my <span className="pic-highlight">PIC</span> using:</h4>
          <button type="button" className="reset-pic-button" onClick={this.handleRedirectLink}>MyADT.com</button>
          <button type="button" className="reset-pic-button reset-mobile" onClick={this.handlePhoneCall}>Call ADT</button>
          <button type="button" className="reset-pic-button reset-desktop" onClick={this.handleNothing}>Call 800-238-2727</button>
          <button type="button" className="reset-pic-cancel" onClick={this.handleCancel}>Cancel</button>
          <div className="disclaimer-container">
            <a href="https://www.myadt.com/usage-agreement" target="_blank" rel="noreferrer">Terms of Use</a>
            &#x2022;
            <a href="https://www.adt.com/about-adt/legal/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPic;