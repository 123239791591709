import Axios from 'axios';
import qs from 'qs';
import Emitter from './Emitter';

function Verify(verifyData) {

    let code = verifyData.code;
    let uuid = verifyData.uuid;

    let token = verifyData.token;
    let verifyType = verifyData.type;
    let data = {
        'code'   : code,
        'action': 'verify',
        'object': uuid,
        'token': token,
        'type': verifyType
    }

    let options = {
        'headers': {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    Axios.post(window.omniFuncUrl, qs.stringify(data), options).then(function (response) {
        let metadata = response.data;

        window.localStorage.setItem('token', metadata.token);
        window.localStorage.setItem('tokenTimestamp', Date.now());
        
        Emitter.emit('MEDIA_DATA_FETCHED', metadata);
        Emitter.emit('VERIFY_FINISHED', true);
    }, function (error) {
        Emitter.emit('VERIFY_ERROR', 'Your verification code is not correct.');
        Emitter.emit('VERIFY_FINISHED', false);
    });
}

export default Verify;