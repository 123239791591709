'use strict mode';

import React from 'react';
import Auth from '../Auth';
import Emitter from '../Emitter';
import {AsYouType, parsePhoneNumber, isValidPhoneNumber} from 'libphonenumber-js'

import '../App.css';

interface LProps {
  uuid: string;
  errorMsg?: string;
}

interface LState {
  error?: string;
  submitDisabled?: boolean;
}

class Login extends React.Component<LProps, LState> {

  public uuid;

  constructor(props) {
    super(props);
    this.submitAuth = this.submitAuth.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleForgotPic = this.handleForgotPic.bind(this);
    this.getErrorMsg = this.getErrorMsg.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.getButtonDisabled = this.getButtonDisabled.bind(this);
    this.uuid = props.uuid;
    this.state = {
      error: props.errorMsg,
      submitDisabled: true,
    };
    Emitter.on('LOGIN_ERROR', this.handleError);
  }

  submitAuth(event) {
    event.preventDefault();
    let phoneNumber = document.getElementById('phoneNumber') as HTMLInputElement;
    let pic = document.getElementById('pic') as HTMLInputElement;

    let parsedPhoneNumber = parsePhoneNumber(phoneNumber.value, 'US');

    let authData = {
      phoneNumber: parsedPhoneNumber.nationalNumber,
      pic: pic.value,
      uuid: this.uuid,
      action: 'auth',
    };
    this.setState({
      submitDisabled: true,
    });
    Auth(authData);
    return false;
  }

  handleTextChange(event) {
    if(event.target.value !== '') {
      event.target.nextElementSibling.classList.add('active');
    } else {
      event.target.nextElementSibling.classList.remove('active');
    }

    if(event.target.type === 'tel') {
      // 10 for the digits, 2 for the bracket, 1 for the space, 1 for the dash, in total, 14

      let offset = event.target.value[0] === "1" ? 2 : 0; // In case there's a leading 1

      event.target.value = new AsYouType('US').input(event.target.value.substring(0,14+offset));
      let inputValue = event.target.value;
      if(inputValue[inputValue.length-1] === ")") {
        event.target.setSelectionRange(inputValue.length-1, inputValue.length-1);
      }
    }

    this.setState({
      submitDisabled: this.getButtonDisabled(),
    });
  }

  getButtonDisabled() {
    let phoneNumber = document.getElementById('phoneNumber') as HTMLInputElement;
    let pic = document.getElementById('pic') as HTMLInputElement;
    if (phoneNumber == null || pic == null) {
      return true
    }
    let parsedNumber = isValidPhoneNumber(phoneNumber.value, 'US');
    return (pic.value === '' || !(parsedNumber));
  }

  handleError(error) {
    this.setState({
      error: error,
      submitDisabled: this.getButtonDisabled(),
    })
  }

  handleForgotPic(event) {
    event.preventDefault();
    Emitter.emit('RESET_PIC', {resetPic: true})
  }

  componentDidMount() {
    Emitter.on('LOGIN_ERROR', this.handleError);
  }

  getErrorMsg() {
    if(this.state && this.state.error) {
      return <div className="error">{this.state.error}</div>;
    }
    else {
      return '';
    }
  }

  render() {
    let errorMsg = this.getErrorMsg();

    let logoUrl = window.localStorage.getItem('cdnUrl') + '/adt-new-logo.svg';
    let isDisabled = this.state.submitDisabled;
    return (
      <div className="form-container">
        <div className="form-header">
          <img src={logoUrl} alt="ADT Logo"/>
        </div>
        <div className="form-body">
          {errorMsg}
          <form className="authForm" method="post" action="/login" autoComplete="false">
            <h1 className="login-header">Log in</h1>
            <div className="input-container phone-number-input" id="phone-number-container">
              <input
                type="tel"
                name="phoneNumber"
                className="text-input"
                id="phoneNumber"
                autoComplete="off"
                onChange={this.handleTextChange}
              />
              <label className="form-label" htmlFor="phoneNumber">Phone Number:</label>
            </div>
            <div className="input-container pic-input">
              <input
                className="text-input"
                name="pic"
                id="pic"
                type="password"
                autoComplete="off"
                onChange={this.handleTextChange}
              />
              <label className="form-label" htmlFor="pic">PIC:</label>
            </div>
            <div className="input-container">
              <button className="start-reset-pic-button" onClick={this.handleForgotPic}>Forgot PIC?</button>
            </div>
            <div className="submit">
              <button className="submit-button full-width" id="submit-auth-button" type="submit" disabled={isDisabled} onClick={this.submitAuth}>Log In</button>
            </div>
          </form>
          <div className="disclaimer-container">
            <p>
            <a href="https://www.myadt.com/usage-agreement" target="_blank" rel="noreferrer">Terms of Use</a>
             &#x2022;
            <a href="https://www.adt.com/about-adt/legal/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;