'use strict mode';

import Emitter from '../Emitter';
import React from 'react';
import '../App.css';

interface LProps {
}

interface LState {
  channel?: string;
  submitDisabled?: boolean;
}

class LoginVerify extends React.Component<LProps, LState> {

  constructor(props) {
    super(props);
    this.handlePhoneCall = this.handlePhoneCall.bind(this);
    this.handleSms = this.handleSms.bind(this);
    this.setState({
      submitDisabled: true,
    });
  }


  handleSms(event) {
    event.preventDefault();
    Emitter.emit("VERIFY_SUBMIT", { type: "sms" });
  }

  handlePhoneCall(event) {
    event.preventDefault();
    Emitter.emit("VERIFY_SUBMIT", { type: "call" });
  }

  render() {
    let logoUrl = window.localStorage.getItem('cdnUrl') + '/adt-new-logo.svg';

    return (
      <div className="form-container">
      <div className="form-header">
        <img src={logoUrl} alt="ADT Logo"/>
      </div>
      <div className="form-body">
        <p>
          Choose a method to receive your verification code to complete the login process. This code will be valid for 10 minutes.
        </p>
        <div>
          <button type="button" className="verify-type-button bottom-margin" onClick={this.handleSms}>SMS/Text</button>
        </div>
        <div>
          <button type="button" className="verify-type-button bottom-margin" onClick={this.handlePhoneCall}>Phone call</button>
        </div>
        <div className="disclaimer-container">
          <a href="https://www.myadt.com/usage-agreement" target="_blank" rel="noreferrer">Terms of Use</a>
           &#x2022;
          <a href="https://www.adt.com/about-adt/legal/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
        </div>
      </div>
    </div>
    );
  }
}

export default LoginVerify;