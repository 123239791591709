"use strict mode";
import React from "react";
import Verify from "../Verify";
import Emitter from "../Emitter";
import ReverifyButton from "./ReverifyButton";

import "../App.css";
interface TVProps {
    uuid: string;
    type: string;
}

interface TVState {
    error?: string;
    submitDisabled?: boolean;
}

class TwilioVerify extends React.Component<TVProps, TVState> {
    public uuid;

    constructor(props) {
        super(props);
        this.submitVerify = this.submitVerify.bind(this);
        this.handleErrorMsg = this.handleErrorMsg.bind(this);
        this.getErrorMsg = this.getErrorMsg.bind(this);
        this.handleResend = this.handleResend.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.uuid = props.uuid;
        this.state = {
            error: "",
            submitDisabled: true,
        };
    }

    submitVerify(event) {
        event.preventDefault();
        let code = document.getElementById("verify") as HTMLInputElement;
        let token = window.localStorage.getItem("token");

        let verifyData = {
            code: code.value,
            uuid: this.uuid,
            token: token,
        };

        this.setState({
            submitDisabled: true,
        });

        Verify(verifyData);
        return false;
    }

    componentDidMount() {
        Emitter.on("VERIFY_ERROR", this.handleErrorMsg);
    }

    handleErrorMsg(errorMsg) {
        this.setState({
            error: errorMsg,
        });
    }

    handleTextChange(event) {
        if (event.target.value !== "") {
            event.target.nextElementSibling.classList.add("active");
        } else {
            event.target.nextElementSibling.classList.remove("active");
        }

        let isDisabled = event.target.value === "";
        this.setState({
            submitDisabled: isDisabled,
        });
    }

    handleResend(event, switchType = false) {
        let resendType = this.props.type;
        if (switchType) {
            resendType = this.props.type === "call" ? "sms" : "call";
        }

        event.preventDefault();
        let code = document.getElementById("verify") as HTMLInputElement;

        code.value = "";

        this.handleTextChange({ target: code });
        Emitter.emit("VERIFY_RESEND", { type: resendType });
    }

    getErrorMsg() {
        if (this.state.error) {
            return <div className="error">{this.state.error}</div>;
        }

        return "";
    }

    render() {
        const logoUrl =
            window.localStorage.getItem("cdnUrl") + "/adt-new-logo.svg";
        const errorMsg = this.getErrorMsg();
        const verificationMessage = `Enter the verification code you received via ${
            this.props.type === "call" ? "a phone call" : "SMS/Text message"
        }`;
        const alternateVerificationMessage = `${
            this.props.type === "call"
                ? "Get an SMS/Text message instead"
                : "Get phone call instead"
        }`;
        const isDisabled = this.state.submitDisabled;

        return (
            <div>
                <div className="form-header">
                    <img src={logoUrl} alt="ADT Logo" />
                </div>
                <div className="form-body">
                    <div className="twilio-verify-header">
                        {verificationMessage}
                    </div>
                    {errorMsg}
                    <div className="input-container pic-input">
                        <input
                            className="text-input"
                            name="verify"
                            id="verify"
                            type="text"
                            autoComplete="off"
                            onChange={this.handleTextChange}
                        />
                        <label className="form-label" htmlFor="verify">
                            Verification Code:
                        </label>
                    </div>
                    <div className="buttons-container">
                        <ReverifyButton
                            resend={this.handleResend}
                            alternateVerificationMessage={
                                alternateVerificationMessage
                            }
                        />
                        <button
                            className="verify-button submit-button"
                            disabled={isDisabled}
                            type="button"
                            onClick={this.submitVerify}
                        >
                            Confirm
                        </button>
                    </div>
                    <div className="disclaimer-container">
                        <span id="portalSpan"></span>
                        <span className="float-children-right">
                            <a
                                href="https://www.myadt.com/usage-agreement"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms of Use
                            </a>
                            <span>&#x2022;</span>
                            <a
                                href="https://www.adt.com/about-adt/legal/privacy-policy"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default TwilioVerify;
