import React from 'react';
import PropTypes from 'prop-types';

interface IProps {
    uuid: string;
    mediaSrc?: string;
    mediaType?: string;
}

interface IState {

}

class VideoPlayer extends React.Component<IProps, IState> {
    static propTypes = {
        uuid: PropTypes.string.isRequired,
        mediaSrc: PropTypes.string.isRequired,
        mediaType: PropTypes.string.isRequired,
    };

    public uuid;
    public triggerRender;

    constructor(props) {
        super(props);
        this.uuid = props.uuid;
        this.triggerRender = props.trigger;
        this.logout = this.logout.bind(this);
        let timeoutWindow = parseInt(window.localStorage.getItem('timeoutWindow'), 10);
        setTimeout(this.logout, timeoutWindow * 60 * 1000);
    }

    logout() {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('tokenTimeout')
        window.localStorage.clear();
        window.location.reload();
    }

    shouldComponentUpdate() {
        return true;
    }

    render() {
        let mediaSrc = this.props.mediaSrc;
        let mediaType = this.props.mediaType;

        return (
            <div>
                {mediaSrc ?
                <div className="video-player">
                    <video max-width="720px" controlsList="nodownload" controls preload="auto" className="video">
                        <source src={mediaSrc} type={mediaType} />
                    </video>
                </div>
                : ""}
                <div className="nav-bar">
                    <button className="logout-button" onClick={this.logout}>LOG OUT</button>
                </div>
            </div>
        );
    }
}
export default VideoPlayer;
