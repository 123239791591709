'use strict mode'
import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

type rbProps = {
    resend: (event: React.MouseEvent<HTMLElement>, swap?: boolean) => void
    alternateVerificationMessage: string
}

const ReverifyButton = ({ resend, alternateVerificationMessage } : rbProps) => {
  const initialTimeout = 15
  const [countdown, setCountdown] = useState(initialTimeout)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountdown(count => {
        if (count <= 0) {
          return 0
        }
        return count - 1
      })
    }, 1000)

    return () => clearTimeout(timer)
  });

  const resendCode = (event : React.MouseEvent<HTMLElement>, swapVerificactionMethod : boolean = false) => {
    resend(event, swapVerificactionMethod)
    setCountdown(initialTimeout)
  }

  const isDisabled = countdown > 0 ? true: false

  return (
    <>
      <button className="verify-button verify-type-button" disabled={isDisabled} onClick={(event) => resendCode(event)}>
        { countdown > 0 ? `Sent (${countdown})` : `Resend Code` }
      </button>
      <Portal >
          <button className="alternate-verification" disabled={isDisabled} type="button" onClick={(e) => resendCode(e, true)}>
            {alternateVerificationMessage}
          </button>
      </Portal>
    </>
  )
}

export const Portal = ({ children }) => {
  const [domReady, setDomReady] = React.useState(false)

  React.useEffect(() => {
    setDomReady(true)
  }, [])

  return domReady ? 
    ReactDOM.createPortal(children, document.getElementById('portalSpan')) : null
}

export default ReverifyButton
