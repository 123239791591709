function PopulateStorageVars(uuid) {
    if(window.cdnUrl === 'CDN_URL') {
        window.cdnUrl = process.env.REACT_APP_CDN_URL;
    }

    if(window.omniFuncUrl === 'OMNI_URL') {
        window.omniFuncUrl = process.env.REACT_APP_OMNI_URL;
    }

    if(window.timeoutWindow === 'TIMEOUT_WINDOW') {
        window.timeoutWindow = process.env.REACT_APP_TIMEOUT_WINDOW;
    }

    window.localStorage.setItem('omniUrl', window.omniFuncUrl);
    window.localStorage.setItem('cdnUrl', window.cdnUrl);
    window.localStorage.setItem('timeoutWindow', window.timeoutWindow);
}

export default PopulateStorageVars;