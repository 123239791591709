'use strict mode';

import React from 'react';
import '../App.css';

class Error extends React.Component {
  
   render() { 
    return ( 
      <div>
      </div>
    );
  }
}

export default Error;
